* {
  padding: 0;
  margin: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #ffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  background: rgb(221, 221, 221);
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: #08fd;
  border-radius: 10p;
}

.profile-wrap {
  /* background:url('./code-820275.jpg') no-repeat; */
  color: #fff;
  background-size: 1600px;
  background: #111;
  /* clip-path: polygon(100% 0, 100% 100%, 49% 96%, 0 100%, 0 0); */
}

.profile-wrap:hover {}



.profile {
  width: 168px;
  height: 168px;
  border: 10px solid rgba(10, 210, 255, 0.075);
  border-radius: 50%;
  margin: auto;
  display: grid;
  justify-content: center;
  align-items: center;
  padding: 0px;
  animation: 2s linear infinite;
  transition: 0.7s;

}

@keyframes Bd {
  0% {
    border: 0px solid rgba(10, 210, 255, 0.075);
  }


  100% {
    border: 14px solid rgba(10, 210, 255, 0.075);
  }
}

.profile img {
  width: 140px;
  height: 140px;
  border-radius: 50%;
}


.profile-inner-two {
  width: 150px;
  height: 150px;
  border: 9px solid rgba(10, 210, 255, 0.199);
  border-radius: 50%;
  display: grid;
  justify-content: center;
  align-items: center;
}

.profile-inner-one {
  width: 140px;
  height: 140px;
  border: 5px solid rgba(10, 210, 255, 0.452);
  border-radius: 50%;
}


.name-section {
  /* font-family: 'Roboto',Condesed; */
  /* font-family: 'Rambla',
 sans-serif; */
  /* font-family: 'Bad Script',
    cursive; */
  font-family: 'Ubuntu Mono',
    monospace;
  line-height: 1.3;
  padding-top: -20px;
  font-weight: 700;
  margin-top: 20px;
  animation: animates 0.5s linear;
}
@keyframes animates {
  0%{
    transform: rotateX(360deg)
  }
}

.contact-section{
   font-family: 'Ubuntu Mono',
   monospace;
   line-height: 1.5;
   margin-top:19px;
   animation: animates 0.5s linear;
}

.name-section h2 {
  font-size: x;
  font-weight: ;
}

.name-section span {
  font-size: 15px;
  font-weight: 500;
}

.contact-butn-wrap {
  margin-top: 20px;

}

.contact-button {
   font-family: 'Ubuntu Mono',
   monospace !important;
  border-radius: 0 !important;
  width: 25%;
  padding: 8px !important;
  border-radius: 22px !important;
  transition: 0.3s !important;
  color: #fff !important;
  text-transform: capitalize !important;
  font-weight: 500 !important;
  font-size:16px!important;
  margin-bottom:20px!important;
  border:2px solid #f5005780!important;
}

.contact-button:hover {
  color: #fff;
   border:2px solid #fff !important;
}

.card-main {
  max-width: 90%;
  margin: auto;
  height: 400px;
}

.card-main-nav {
  max-width: 70%;
  margin: 50px auto;
  height: 500p;
  display: grid;
  grid-template-columns: 10% auto;
  background: #111 !important;

}


.side-nav-wrap {
  width: 100%;
  display: grid;
  justify-content: center;
  height: 500p;
  border-radius: 0 !important;
  background: #111 !important;
}



.side-nav-wrap li {
  list-style: none;
  height: 136px;
  text-align: center;
  display: grid;
  align-items: center;
  background: #111;
  order-bottom: 1px solid #eee;
  color: #08fdd8;
  font-size: 12px;
}

.side-nav-wrap a {
  text-decoration: none;
  color: #fff;
  display: grid;
  align-items: center;
  text-align: center;
}

.container {
  padding: 10px;
  background: #111;
  color: #fff;
}

.inner-content-about {
  font-family: 'Bad Script',
    cursive;
  padding: 15px;
  border: 1px solid #fff;
  max-width: 80%;
  margin: 100px auto;
  border-left: 4px solid #08fdd8;
  border-radius: 6px;
  border-top: 1px solid rgba(255, 255, 255, 0.384);
  border-bottom: 1px solid rgba(255, 255, 255, 0.384);
  border-right: 4px solid #fff;
}
.paragraph-section{
  max-width:90%;
  margin:auto;
}

.skills-inner-content {
  font-family: 'Bad Script',
    cursive;
  padding: 15px;
  height: 385p;
  border: 1px solid #fff;
  border-top: 1px solid rgba(255, 255, 255, 0.384);
  border-bottom: 1px solid rgba(255, 255, 255, 0.384);
  border-right: 4px solid #fff;
  max-width: 80%;
  margin: 40px auto;
  border-left: 4px solid #08fdd8;
  border-radius: 6px;
  transition: 0.3s;
}

.skills-section {
  margin-top: 20px;
}

.skill-progress {
  font-family: 'Asap',
    sans-serif;
  width: 100%;
  height: 20px;
  border: 1px solid#eee;
  border-radius: 4px;
  position: relative;
  display: grid;
  grid-template-columns: auto 20%;
  align-items: center;

}

.skill-progress-inner {
  height: 16px;
  background: #08fdd8;
  display: grid;
  align-items: center;
  justify-items: center;
  font-size: 13px;
  padding: 2px;
  color: #111;

}

.skills-grid-container {
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: cente;
  width: 90%;
  margin: 20px auto;
  gap: 15px;
}

@media (max-width:500px) {
  .profile-inner-container {
    width:96%;
    margin:auto;
  }
  .inner-content-about {
    font-family: 'Bad Script',
      cursive;
    padding: 15px;
    border: 1px solid #fff;
    max-width: 80%;
    margin: 100px auto;
    border-left: 4px solid #08fdd8;
    border-top: 1px solid rgba(255, 255, 255, 0.384);
    border-bottom: 1px solid rgba(255, 255, 255, 0.384);
    border-right: 4px solid #fff;
  }

  .side-nav-wrap {
    width: 100%;
    display: block;
    border-radius: 0 !important;
    background: #111;
  }

  .card-main-nav {
    max-width: 100%;
    margin: 10px auto;
    display: grid;
    grid-template-columns: 16% auto!important;
  }

  .skills-inner-content {
    font-family: 'Bad Script',
      cursive;
    padding: 15px;

    border: 1px solid #fff;
    border-top: 1px solid rgba(255, 255, 255, 0.384);
    border-bottom: 1px solid rgba(255, 255, 255, 0.384);
    border-right: 4px solid #fff;
    max-width: 80%;
    margin: 20px auto;
    border-left: 4px solid #08fdd8;
    border-radius: 6px;
    transition: 0.3s;
  }

  .skills-grid-container {
    display: grid;
    grid-template-columns: 1fr;
    width: 95%;
    margin: 20px auto;
    gap: 15px;
  }

  .side-nav-wrap li {
    list-style: none;
    height: 142px;
    text-align: center;
    display: grid;
    align-items: center;
    background: #111;
    color: #08fdd8;
    font-size: 12px;
  }

 .contact-button{
   width:65%;
   border-radius: 0!important;
      font-family: 'Ubuntu Mono',
      monospace!important;
      font-size:16px!important;
 }
}

@media (max-width:1024px) {
  .card-main-nav {
    max-width: 98%;
    margin: 50px auto;
    height: 500p;
    display: grid;
    grid-template-columns: 10% auto;
    background: #111 !important;

  }
}

@media (max-width:768px) {
  .card-main-nav {
    max-width: 98%;
    margin: 50px auto;
    height: 500p;
    display: grid;
    grid-template-columns: 10% auto;
    background: #111 !important;

  }
}

@media (max-width:416px) {
  .card-main-nav {
    max-width: 100%;
    margin: 10px auto;
    height: 500p;
    display: grid;
    grid-template-columns: 13% auto;
    background: #111 !important;
    border-radius: 0 !important;
  }

  .side-nav-wrap {
    text-align: center !important;
  }
}

@media (max-width:320px) {
 .skills-inner-content {
      font-family: 'Bad Script',
        cursive;
      padding: 15px;
      border: 1px solid #fff;
      border-top: 1px solid rgba(255, 255, 255, 0.384);
      border-bottom: 1px solid rgba(255, 255, 255, 0.384);
      border-right: 4px solid #fff;
      max-width: 90%;
      margin: 20px auto;
      border-left: 4px solid #08fdd8;
      border-radius: 6px;
      transition: 0.3s;
    }

    .contact-button {
      font-family: 'Bad Script',
        cursive !important;
      border-radius: 0 !important;
      width: 65%;
      padding: 8px !important;
      transition: 0.3s !important;
      color: #fff !important;
      text-transform: capitalize !important;
      font-weight: 500 !important;
      margin-bottom: 20px !important;
    }

      .skills-grid-container {
        display: grid;
        grid-template-columns: 1fr;
        justify-content: cente;
        width: 100%;
        margin: 20px auto;
        gap: 15px;
      }
}